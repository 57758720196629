@charset "UTF-8";
*, *:before, *:after {
  box-sizing: border-box;
}

html {
  text-size-adjust: 100%;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

h1 {
  font-size: 100%;
}

hr {
  height: 1px;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

template {
  display: none;
}

input,
textarea,
select {
  appearance: none;
  border-radius: 0;
}

button {
  overflow: visible;
  text-transform: none;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

textarea {
  overflow: auto;
}

select {
  text-transform: none;
}

b,
strong {
  font-weight: bold;
}

a {
  background: none;
}

abbr {
  speak: spell-out;
}

img {
  border: 0;
}

small {
  font-size: 100%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

svg:not(:root) {
  overflow: hidden;
}

[hidden] {
  display: none;
}

* {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

ol,
ul {
  list-style: none;
}

pre,
code {
  font-family: monospace, monospace;
}

hr {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  font-weight: normal;
  text-align: left;
}

button,
input,
textarea
select,
optgroup {
  color: inherit;
  font: inherit;
}

button,
input,
textarea,
select {
  background: none;
  border: 0;
  outline: 0;
}

fieldset,
legend {
  border: 0;
}

optgroup {
  font-weight: bold;
}

address,
cite {
  font-style: normal;
}

a {
  outline: 0;
  text-decoration: none;
}

iframe {
  border: 0;
}

@font-face {
  font-family: remind-proxima-nova;
  font-style: normal;
  font-weight: bold;
  src: url("https://d3498ple9xfqkw.cloudfront.net/common/fonts/remind-proxima-nova/remind-proxima-nova-bold.eot?") format("embedded-opentype"), url("https://d3498ple9xfqkw.cloudfront.net/common/fonts/remind-proxima-nova/remind-proxima-nova-bold.woff2") format("woff2"), url("https://d3498ple9xfqkw.cloudfront.net/common/fonts/remind-proxima-nova/remind-proxima-nova-bold.woff") format("woff"), url("https://d3498ple9xfqkw.cloudfront.net/common/fonts/remind-proxima-nova/remind-proxima-nova-bold.ttf") format("truetype");
}

@font-face {
  font-family: remind-proxima-nova;
  font-style: normal;
  font-weight: 600;
  src: url("https://d3498ple9xfqkw.cloudfront.net/common/fonts/remind-proxima-nova/remind-proxima-nova-semibold.eot?") format("embedded-opentype"), url("https://d3498ple9xfqkw.cloudfront.net/common/fonts/remind-proxima-nova/remind-proxima-nova-semibold.woff2") format("woff2"), url("https://d3498ple9xfqkw.cloudfront.net/common/fonts/remind-proxima-nova/remind-proxima-nova-semibold.woff") format("woff"), url("https://d3498ple9xfqkw.cloudfront.net/common/fonts/remind-proxima-nova/remind-proxima-nova-semibold.ttf") format("truetype");
}

@font-face {
  font-family: remind-proxima-nova;
  font-style: normal;
  font-weight: normal;
  src: url("https://d3498ple9xfqkw.cloudfront.net/common/fonts/remind-proxima-nova/remind-proxima-nova-regular.eot?") format("embedded-opentype"), url("https://d3498ple9xfqkw.cloudfront.net/common/fonts/remind-proxima-nova/remind-proxima-nova-regular.woff2") format("woff2"), url("https://d3498ple9xfqkw.cloudfront.net/common/fonts/remind-proxima-nova/remind-proxima-nova-regular.woff") format("woff"), url("https://d3498ple9xfqkw.cloudfront.net/common/fonts/remind-proxima-nova/remind-proxima-nova-regular.ttf") format("truetype");
}

@font-face {
  font-family: remind-proxima-nova;
  font-style: italic;
  font-weight: normal;
  src: url("https://d3498ple9xfqkw.cloudfront.net/common/fonts/remind-proxima-nova/remind-proxima-nova-regular-italic.eot?") format("embedded-opentype"), url("https://d3498ple9xfqkw.cloudfront.net/common/fonts/remind-proxima-nova/remind-proxima-nova-regular-italic.woff2") format("woff2"), url("https://d3498ple9xfqkw.cloudfront.net/common/fonts/remind-proxima-nova/remind-proxima-nova-regular-italic.woff") format("woff"), url("https://d3498ple9xfqkw.cloudfront.net/common/fonts/remind-proxima-nova/remind-proxima-nova-regular-italic.ttf") format("truetype");
}

@font-face {
  font-family: remind-proxima-nova;
  font-style: normal;
  font-weight: 300;
  src: url("https://d3498ple9xfqkw.cloudfront.net/common/fonts/remind-proxima-nova/remind-proxima-nova-light.eot?") format("embedded-opentype"), url("https://d3498ple9xfqkw.cloudfront.net/common/fonts/remind-proxima-nova/remind-proxima-nova-light.woff2") format("woff2"), url("https://d3498ple9xfqkw.cloudfront.net/common/fonts/remind-proxima-nova/remind-proxima-nova-light.woff") format("woff"), url("https://d3498ple9xfqkw.cloudfront.net/common/fonts/remind-proxima-nova/remind-proxima-nova-light.ttf") format("truetype");
}

body,
button,
input,
textarea,
select {
  font-family: remind-proxima-nova, helvetica neue, arial, sans-serif;
}

body {
  line-height: 1;
}

p,
blockquote {
  line-height: normal;
}

button,
label,
input[type="checkbox"],
input[type="radio"],
select {
  cursor: pointer;
}

textarea {
  resize: vertical;
  vertical-align: top;
}

b,
strong {
  font-weight: 600;
}

img,
picture {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

a {
  color: inherit;
  cursor: pointer;
}

[disabled],
[disabled] * {
  cursor: default !important;
}

@viewport {
  width: device-width;
}

.columns-reset-lt-full {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.columns-reset-desktop {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.columns-reset-lt-desktop {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.columns-reset-tablet {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.columns-reset-lt-tablet {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.columns-reset-mobile-med {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.columns-reset-lt-mobile-med {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.columns-reset-mobile {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.columns-reset-lt-mobile {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.columns-reset-mobile-sm {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.row, .row:last-child {
  margin-bottom: 80px;
}

@media (max-width: 1318px) {
  .row-lt-full {
    margin-bottom: 80px;
  }
}

@media (max-width: 980px) {
  .row-desktop {
    margin-bottom: 80px;
  }
}

@media (max-width: 979px) {
  .row-lt-desktop {
    margin-bottom: 80px;
  }
}

@media (max-width: 768px) {
  .row-tablet {
    margin-bottom: 80px;
  }
}

@media (max-width: 767px) {
  .row-lt-tablet {
    margin-bottom: 80px;
  }
}

@media (max-width: 480px) {
  .row-mobile-med {
    margin-bottom: 80px;
  }
}

@media (max-width: 479px) {
  .row-lt-mobile-med {
    margin-bottom: 80px;
  }
}

@media (max-width: 568px) {
  .row-mobile {
    margin-bottom: 80px;
  }
}

@media (max-width: 567px) {
  .row-lt-mobile {
    margin-bottom: 80px;
  }
}

@media (max-width: 320px) {
  .row-mobile-sm {
    margin-bottom: 80px;
  }
}

.columns {
  margin-right: -20px;
  margin-left: -20px;
}

.columns::before, .columns::after {
  content: '';
  display: table;
}

.columns::after {
  clear: both;
}

.columns > [class*="column-"] {
  padding: 0 20px;
  min-height: 1px;
  float: left;
}

.columns-rev > [class*="column-"] {
  float: right;
}

.column-1 {
  width: 100%;
}

.column-solo {
  float: none;
}

.column-center {
  margin: auto;
}

.columns > .column-center {
  float: none;
  margin: auto;
}

.column--1 {
  width: 100%;
}

.column-1-2 {
  width: 50%;
}

.column-2-2 {
  width: 100%;
}

.column-1-3 {
  width: 33.3333333333%;
}

.column-2-3 {
  width: 66.6666666667%;
}

.column-3-3 {
  width: 100%;
}

.column-1-4 {
  width: 25%;
}

.column-2-4 {
  width: 50%;
}

.column-3-4 {
  width: 75%;
}

.column-4-4 {
  width: 100%;
}

.column-1-5 {
  width: 20%;
}

.column-2-5 {
  width: 40%;
}

.column-3-5 {
  width: 60%;
}

.column-4-5 {
  width: 80%;
}

.column-5-5 {
  width: 100%;
}

@media (max-width: 1318px) {
  .column-lt-full-1 {
    width: 100%;
  }
  .column-lt-full-1-2 {
    width: 50%;
  }
  .column-lt-full-2-2 {
    width: 100%;
  }
  .column-lt-full-1-3 {
    width: 33.3333333333%;
  }
  .column-lt-full-2-3 {
    width: 66.6666666667%;
  }
  .column-lt-full-3-3 {
    width: 100%;
  }
  .column-lt-full-1-4 {
    width: 25%;
  }
  .column-lt-full-2-4 {
    width: 50%;
  }
  .column-lt-full-3-4 {
    width: 75%;
  }
  .column-lt-full-4-4 {
    width: 100%;
  }
  .column-lt-full-1-5 {
    width: 20%;
  }
  .column-lt-full-2-5 {
    width: 40%;
  }
  .column-lt-full-3-5 {
    width: 60%;
  }
  .column-lt-full-4-5 {
    width: 80%;
  }
  .column-lt-full-5-5 {
    width: 100%;
  }
  .columns-reset-lt-full {
    margin-right: 0;
    margin-left: 0;
  }
  .columns-reset-lt-full > [class*="column-"] {
    width: auto;
    float: none;
    padding-right: 0;
    padding-left: 0;
  }
}

@media (max-width: 980px) {
  .column-desktop-1 {
    width: 100%;
  }
  .column-desktop-1-2 {
    width: 50%;
  }
  .column-desktop-2-2 {
    width: 100%;
  }
  .column-desktop-1-3 {
    width: 33.3333333333%;
  }
  .column-desktop-2-3 {
    width: 66.6666666667%;
  }
  .column-desktop-3-3 {
    width: 100%;
  }
  .column-desktop-1-4 {
    width: 25%;
  }
  .column-desktop-2-4 {
    width: 50%;
  }
  .column-desktop-3-4 {
    width: 75%;
  }
  .column-desktop-4-4 {
    width: 100%;
  }
  .column-desktop-1-5 {
    width: 20%;
  }
  .column-desktop-2-5 {
    width: 40%;
  }
  .column-desktop-3-5 {
    width: 60%;
  }
  .column-desktop-4-5 {
    width: 80%;
  }
  .column-desktop-5-5 {
    width: 100%;
  }
  .columns-reset-desktop {
    margin-right: 0;
    margin-left: 0;
  }
  .columns-reset-desktop > [class*="column-"] {
    width: auto;
    float: none;
    padding-right: 0;
    padding-left: 0;
  }
}

@media (max-width: 979px) {
  .column-lt-desktop-1 {
    width: 100%;
  }
  .column-lt-desktop-1-2 {
    width: 50%;
  }
  .column-lt-desktop-2-2 {
    width: 100%;
  }
  .column-lt-desktop-1-3 {
    width: 33.3333333333%;
  }
  .column-lt-desktop-2-3 {
    width: 66.6666666667%;
  }
  .column-lt-desktop-3-3 {
    width: 100%;
  }
  .column-lt-desktop-1-4 {
    width: 25%;
  }
  .column-lt-desktop-2-4 {
    width: 50%;
  }
  .column-lt-desktop-3-4 {
    width: 75%;
  }
  .column-lt-desktop-4-4 {
    width: 100%;
  }
  .column-lt-desktop-1-5 {
    width: 20%;
  }
  .column-lt-desktop-2-5 {
    width: 40%;
  }
  .column-lt-desktop-3-5 {
    width: 60%;
  }
  .column-lt-desktop-4-5 {
    width: 80%;
  }
  .column-lt-desktop-5-5 {
    width: 100%;
  }
  .columns-reset-lt-desktop {
    margin-right: 0;
    margin-left: 0;
  }
  .columns-reset-lt-desktop > [class*="column-"] {
    width: auto;
    float: none;
    padding-right: 0;
    padding-left: 0;
  }
}

@media (max-width: 768px) {
  .column-tablet-1 {
    width: 100%;
  }
  .column-tablet-1-2 {
    width: 50%;
  }
  .column-tablet-2-2 {
    width: 100%;
  }
  .column-tablet-1-3 {
    width: 33.3333333333%;
  }
  .column-tablet-2-3 {
    width: 66.6666666667%;
  }
  .column-tablet-3-3 {
    width: 100%;
  }
  .column-tablet-1-4 {
    width: 25%;
  }
  .column-tablet-2-4 {
    width: 50%;
  }
  .column-tablet-3-4 {
    width: 75%;
  }
  .column-tablet-4-4 {
    width: 100%;
  }
  .column-tablet-1-5 {
    width: 20%;
  }
  .column-tablet-2-5 {
    width: 40%;
  }
  .column-tablet-3-5 {
    width: 60%;
  }
  .column-tablet-4-5 {
    width: 80%;
  }
  .column-tablet-5-5 {
    width: 100%;
  }
  .columns-reset-tablet {
    margin-right: 0;
    margin-left: 0;
  }
  .columns-reset-tablet > [class*="column-"] {
    width: auto;
    float: none;
    padding-right: 0;
    padding-left: 0;
  }
}

@media (max-width: 767px) {
  .column-lt-tablet-1 {
    width: 100%;
  }
  .column-lt-tablet-1-2 {
    width: 50%;
  }
  .column-lt-tablet-2-2 {
    width: 100%;
  }
  .column-lt-tablet-1-3 {
    width: 33.3333333333%;
  }
  .column-lt-tablet-2-3 {
    width: 66.6666666667%;
  }
  .column-lt-tablet-3-3 {
    width: 100%;
  }
  .column-lt-tablet-1-4 {
    width: 25%;
  }
  .column-lt-tablet-2-4 {
    width: 50%;
  }
  .column-lt-tablet-3-4 {
    width: 75%;
  }
  .column-lt-tablet-4-4 {
    width: 100%;
  }
  .column-lt-tablet-1-5 {
    width: 20%;
  }
  .column-lt-tablet-2-5 {
    width: 40%;
  }
  .column-lt-tablet-3-5 {
    width: 60%;
  }
  .column-lt-tablet-4-5 {
    width: 80%;
  }
  .column-lt-tablet-5-5 {
    width: 100%;
  }
  .columns-reset-lt-tablet {
    margin-right: 0;
    margin-left: 0;
  }
  .columns-reset-lt-tablet > [class*="column-"] {
    width: auto;
    float: none;
    padding-right: 0;
    padding-left: 0;
  }
}

@media (max-width: 480px) {
  .column-mobile-med-1 {
    width: 100%;
  }
  .column-mobile-med-1-2 {
    width: 50%;
  }
  .column-mobile-med-2-2 {
    width: 100%;
  }
  .column-mobile-med-1-3 {
    width: 33.3333333333%;
  }
  .column-mobile-med-2-3 {
    width: 66.6666666667%;
  }
  .column-mobile-med-3-3 {
    width: 100%;
  }
  .column-mobile-med-1-4 {
    width: 25%;
  }
  .column-mobile-med-2-4 {
    width: 50%;
  }
  .column-mobile-med-3-4 {
    width: 75%;
  }
  .column-mobile-med-4-4 {
    width: 100%;
  }
  .column-mobile-med-1-5 {
    width: 20%;
  }
  .column-mobile-med-2-5 {
    width: 40%;
  }
  .column-mobile-med-3-5 {
    width: 60%;
  }
  .column-mobile-med-4-5 {
    width: 80%;
  }
  .column-mobile-med-5-5 {
    width: 100%;
  }
  .columns-reset-mobile-med {
    margin-right: 0;
    margin-left: 0;
  }
  .columns-reset-mobile-med > [class*="column-"] {
    width: auto;
    float: none;
    padding-right: 0;
    padding-left: 0;
  }
}

@media (max-width: 479px) {
  .column-lt-mobile-med-1 {
    width: 100%;
  }
  .column-lt-mobile-med-1-2 {
    width: 50%;
  }
  .column-lt-mobile-med-2-2 {
    width: 100%;
  }
  .column-lt-mobile-med-1-3 {
    width: 33.3333333333%;
  }
  .column-lt-mobile-med-2-3 {
    width: 66.6666666667%;
  }
  .column-lt-mobile-med-3-3 {
    width: 100%;
  }
  .column-lt-mobile-med-1-4 {
    width: 25%;
  }
  .column-lt-mobile-med-2-4 {
    width: 50%;
  }
  .column-lt-mobile-med-3-4 {
    width: 75%;
  }
  .column-lt-mobile-med-4-4 {
    width: 100%;
  }
  .column-lt-mobile-med-1-5 {
    width: 20%;
  }
  .column-lt-mobile-med-2-5 {
    width: 40%;
  }
  .column-lt-mobile-med-3-5 {
    width: 60%;
  }
  .column-lt-mobile-med-4-5 {
    width: 80%;
  }
  .column-lt-mobile-med-5-5 {
    width: 100%;
  }
  .columns-reset-lt-mobile-med {
    margin-right: 0;
    margin-left: 0;
  }
  .columns-reset-lt-mobile-med > [class*="column-"] {
    width: auto;
    float: none;
    padding-right: 0;
    padding-left: 0;
  }
}

@media (max-width: 568px) {
  .column-mobile-1 {
    width: 100%;
  }
  .column-mobile-1-2 {
    width: 50%;
  }
  .column-mobile-2-2 {
    width: 100%;
  }
  .column-mobile-1-3 {
    width: 33.3333333333%;
  }
  .column-mobile-2-3 {
    width: 66.6666666667%;
  }
  .column-mobile-3-3 {
    width: 100%;
  }
  .column-mobile-1-4 {
    width: 25%;
  }
  .column-mobile-2-4 {
    width: 50%;
  }
  .column-mobile-3-4 {
    width: 75%;
  }
  .column-mobile-4-4 {
    width: 100%;
  }
  .column-mobile-1-5 {
    width: 20%;
  }
  .column-mobile-2-5 {
    width: 40%;
  }
  .column-mobile-3-5 {
    width: 60%;
  }
  .column-mobile-4-5 {
    width: 80%;
  }
  .column-mobile-5-5 {
    width: 100%;
  }
  .columns-reset-mobile {
    margin-right: 0;
    margin-left: 0;
  }
  .columns-reset-mobile > [class*="column-"] {
    width: auto;
    float: none;
    padding-right: 0;
    padding-left: 0;
  }
}

@media (max-width: 567px) {
  .column-lt-mobile-1 {
    width: 100%;
  }
  .column-lt-mobile-1-2 {
    width: 50%;
  }
  .column-lt-mobile-2-2 {
    width: 100%;
  }
  .column-lt-mobile-1-3 {
    width: 33.3333333333%;
  }
  .column-lt-mobile-2-3 {
    width: 66.6666666667%;
  }
  .column-lt-mobile-3-3 {
    width: 100%;
  }
  .column-lt-mobile-1-4 {
    width: 25%;
  }
  .column-lt-mobile-2-4 {
    width: 50%;
  }
  .column-lt-mobile-3-4 {
    width: 75%;
  }
  .column-lt-mobile-4-4 {
    width: 100%;
  }
  .column-lt-mobile-1-5 {
    width: 20%;
  }
  .column-lt-mobile-2-5 {
    width: 40%;
  }
  .column-lt-mobile-3-5 {
    width: 60%;
  }
  .column-lt-mobile-4-5 {
    width: 80%;
  }
  .column-lt-mobile-5-5 {
    width: 100%;
  }
  .columns-reset-lt-mobile {
    margin-right: 0;
    margin-left: 0;
  }
  .columns-reset-lt-mobile > [class*="column-"] {
    width: auto;
    float: none;
    padding-right: 0;
    padding-left: 0;
  }
}

@media (max-width: 320px) {
  .column-mobile-sm-1 {
    width: 100%;
  }
  .column-mobile-sm-1-2 {
    width: 50%;
  }
  .column-mobile-sm-2-2 {
    width: 100%;
  }
  .column-mobile-sm-1-3 {
    width: 33.3333333333%;
  }
  .column-mobile-sm-2-3 {
    width: 66.6666666667%;
  }
  .column-mobile-sm-3-3 {
    width: 100%;
  }
  .column-mobile-sm-1-4 {
    width: 25%;
  }
  .column-mobile-sm-2-4 {
    width: 50%;
  }
  .column-mobile-sm-3-4 {
    width: 75%;
  }
  .column-mobile-sm-4-4 {
    width: 100%;
  }
  .column-mobile-sm-1-5 {
    width: 20%;
  }
  .column-mobile-sm-2-5 {
    width: 40%;
  }
  .column-mobile-sm-3-5 {
    width: 60%;
  }
  .column-mobile-sm-4-5 {
    width: 80%;
  }
  .column-mobile-sm-5-5 {
    width: 100%;
  }
  .columns-reset-mobile-sm {
    margin-right: 0;
    margin-left: 0;
  }
  .columns-reset-mobile-sm > [class*="column-"] {
    width: auto;
    float: none;
    padding-right: 0;
    padding-left: 0;
  }
}

/* Comment */
.highlight .c, .highlight .c1 {
  color: #586E75;
}

/* Error */
.highlight .err {
  color: #93A1A1;
}

/* Generic */
.highlight .g {
  color: #93A1A1;
}

/* Keyword */
.highlight .k {
  color: #859900;
}

/* Literal */
.highlight .l {
  color: #93A1A1;
}

/* Name */
.highlight .n {
  color: #93A1A1;
}

/* Operator */
.highlight .o {
  color: #859900;
}

/* Other */
.highlight .x {
  color: #CB4B16;
}

/* Punctuation */
.highlight .p {
  color: #93A1A1;
}

/* Comment.Multiline */
.highlight .cm {
  color: #586E75;
}

/* Comment.Preproc */
.highlight .cp {
  color: #859900;
}

/* Comment.Single */
.highlight .c1 {
  color: #586E75;
}

/* Comment.Special */
.highlight .cs {
  color: #859900;
}

/* Generic.Deleted */
.highlight .gd {
  color: #2AA198;
}

/* Generic.Emph */
.highlight .ge {
  color: #93A1A1;
  font-style: italic;
}

/* Generic.Error */
.highlight .gr {
  color: #DC322F;
}

/* Generic.Heading */
.highlight .gh {
  color: #CB4B16;
}

/* Generic.Inserted */
.highlight .gi {
  color: #859900;
}

/* Generic.Output */
.highlight .go {
  color: #93A1A1;
}

/* Generic.Prompt */
.highlight .gp {
  color: #93A1A1;
}

/* Generic.Strong */
.highlight .gs {
  color: #93A1A1;
  font-weight: bold;
}

/* Generic.Subheading */
.highlight .gu {
  color: #CB4B16;
}

/* Generic.Traceback */
.highlight .gt {
  color: #93A1A1;
}

/* Keyword.Constant */
.highlight .kc {
  color: #CB4B16;
}

/* Keyword.Declaration */
.highlight .kd {
  color: #268BD2;
}

/* Keyword.Namespace */
.highlight .kn {
  color: #859900;
}

/* Keyword.Pseudo */
.highlight .kp {
  color: #859900;
}

/* Keyword.Reserved */
.highlight .kr {
  color: #268BD2;
}

/* Keyword.Type */
.highlight .kt {
  color: #DC322F;
}

/* Literal.Date */
.highlight .ld {
  color: #93A1A1;
}

/* Literal.Number */
.highlight .m {
  color: #2AA198;
}

/* Literal.String */
.highlight .s {
  color: #2AA198;
}

/* Name.Attribute */
.highlight .na {
  color: #93A1A1;
}

/* Name.Builtin */
.highlight .nb {
  color: #B58900;
}

/* Name.Class */
.highlight .nc {
  color: #268BD2;
}

/* Name.Constant */
.highlight .no {
  color: #CB4B16;
}

/* Name.Decorator */
.highlight .nd {
  color: #268BD2;
}

/* Name.Entity */
.highlight .ni {
  color: #CB4B16;
}

/* Name.Exception */
.highlight .ne {
  color: #CB4B16;
}

/* Name.Function */
.highlight .nf {
  color: #268BD2;
}

/* Name.Label */
.highlight .nl {
  color: #93A1A1;
}

/* Name.Namespace */
.highlight .nn {
  color: #93A1A1;
}

/* Name.Other */
.highlight .nx {
  color: #555;
}

/* Name.Property */
.highlight .py {
  color: #93A1A1;
}

/* Name.Tag */
.highlight .nt {
  color: #268BD2;
}

/* Name.Variable */
.highlight .nv {
  color: #268BD2;
}

/* Operator.Word */
.highlight .ow {
  color: #859900;
}

/* Text.Whitespace */
.highlight .w {
  color: #93A1A1;
}

/* Literal.Number.Float */
.highlight .mf {
  color: #2AA198;
}

/* Literal.Number.Hex */
.highlight .mh {
  color: #2AA198;
}

/* Literal.Number.Integer */
.highlight .mi {
  color: #2AA198;
}

/* Literal.Number.Oct */
.highlight .mo {
  color: #2AA198;
}

/* Literal.String.Backtick */
.highlight .sb {
  color: #586E75;
}

/* Literal.String.Char */
.highlight .sc {
  color: #2AA198;
}

/* Literal.String.Doc */
.highlight .sd {
  color: #93A1A1;
}

/* Literal.String.Double */
.highlight .s2 {
  color: #2AA198;
}

/* Literal.String.Escape */
.highlight .se {
  color: #CB4B16;
}

/* Literal.String.Heredoc */
.highlight .sh {
  color: #93A1A1;
}

/* Literal.String.Interpol */
.highlight .si {
  color: #2AA198;
}

/* Literal.String.Other */
.highlight .sx {
  color: #2AA198;
}

/* Literal.String.Regex */
.highlight .sr {
  color: #DC322F;
}

/* Literal.String.Single */
.highlight .s1 {
  color: #2AA198;
}

/* Literal.String.Symbol */
.highlight .ss {
  color: #2AA198;
}

/* Name.Builtin.Pseudo */
.highlight .bp {
  color: #268BD2;
}

/* Name.Variable.Class */
.highlight .vc {
  color: #268BD2;
}

/* Name.Variable.Global */
.highlight .vg {
  color: #268BD2;
}

/* Name.Variable.Instance */
.highlight .vi {
  color: #268BD2;
}

/* Literal.Number.Integer.Long */
.highlight .il {
  color: #2AA198;
}

body {
  background: #f9f9f9;
  color: #717376;
  font-size: 18px;
  text-align: center;
}

h1 {
  font-size: 36px;
  line-height: 1.4;
}

h2 {
  font-size: 22px;
  margin: 0 0 30px;
}

p,
blockquote {
  line-height: 1.35;
}

hr {
  background: #eeeeee;
  margin: 24px 0 0;
}

a {
  color: #4a89dc;
}

a:hover {
  color: #1b4c8c;
}

a:active {
  color: #133461;
}

pre code {
  font-size: 14px;
}

code {
  color: #282c31;
  font-family: courier, monospace;
  font-size: 90%;
}

.wrap {
  max-width: 1320px;
  background: url("/img/bg.jpg") center top no-repeat #fff;
  border: 1px #eeeeee solid;
  border-width: 0 1px;
  margin: auto;
  position: relative;
}

@media (max-width: 1318px) {
  .wrap {
    border: 0;
  }
}

.header {
  width: 100%;
  color: #fff;
  padding: 46px 0 0;
  position: absolute;
  left: 0;
  text-align: left;
}

.header-brand {
  font-size: inherit;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: inherit !important;
}

.header-brand:before {
  content: '';
  width: 38px;
  height: 31px;
  background: url("/img/logos/38x31.svg") no-repeat;
  display: inline-block;
  margin: 0 18px 0 0;
  vertical-align: -9px;
}

@media (max-width: 480px) {
  .header {
    text-align: center;
  }
  .header-brand {
    margin: auto;
  }
  .header-brand:before {
    display: block;
    margin: 0 auto 12px;
  }
}

.footer {
  background: url("/img/bg-small.jpg") center top no-repeat #7d7d7d;
  overflow: hidden;
  padding: 36px 0;
}

.footer-brand {
  width: 121px;
  height: 23px;
  background: url("/img/logos/121x23.svg") no-repeat;
  display: block;
  float: left;
  overflow: hidden;
  text-indent: 150%;
  white-space: nowrap;
}

.footer-nav {
  float: right;
  font-size: 0;
  margin: 2px 0 0;
}

.footer-nav-item {
  width: 17px;
  height: 17px;
  display: inline-block;
}

.footer-nav-item:not(:last-child) {
  margin: 0 20px 0 0;
}

.footer-nav-item-github {
  background: url("/img/socials/github.svg") center no-repeat;
}

.footer-nav-item-linkedin {
  background: url("/img/socials/linkedin.svg") center no-repeat;
}

.footer-nav-item-twitter {
  background: url("/img/socials/twitter.svg") center no-repeat;
}

.footer-nav-item-rss {
  background: url("/img/socials/rss.svg") center no-repeat;
}

.footer-nav-item-twitter {
  width: 20px;
}

.content {
  background: #fff;
  padding: 90px 0 60px;
}

.articles {
  margin: 0 0 -40px;
}

.articles .columns {
  font-size: 0;
}

.articles [class*="column-"] {
  display: inline-block;
  float: none;
  font-size: 18px;
  vertical-align: top;
}

.articles-article {
  border-radius: 4px;
  box-shadow: 0 0 0 1px #eeeeee inset;
  transition: 100ms ease-in-out;
  transition-property: background, box-shadow;
  max-width: 500px;
  border-radius: 4px;
  display: block;
  margin: 0 auto 40px;
  position: relative;
}

.articles-article:hover {
  background: #f5f5f5;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 1px #eeeeee inset, 0 -100px 0 -94px #5d60a5 inset;
}

.articles-article-img {
  width: 100%;
  height: 150px;
  background: url("/img/article-thumb.jpg") center/cover no-repeat;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.articles-article-body {
  min-height: 268px;
  padding: 0 20px 36px;
  position: relative;
}

.articles-article-body:after {
  content: 'Read more';
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  display: block;
  margin: 36px 0 0;
}

@media (max-width: 980px) {
  .articles-article-body {
    min-height: 0;
  }
}

.articles-article-avatar {
  margin: -30px 0 14px;
}

.articles-article-title {
  color: #464d51;
  line-height: 1.2;
  margin: 0 0 15px;
}

.articles-article-date {
  color: #9e9fa5;
  display: block;
  font-size: 12px;
  margin: 0 0 15px;
}

.articles-article-excerpt {
  color: #717376;
  font-size: 14px;
}

.person-avatar {
  display: block;
  margin: 0 auto 10px;
}

.person-name {
  font-weight: 600;
}

.person-position {
  display: block;
  margin: 4px 0 0;
}

.projects {
  margin: 0 0 -40px;
}

.projects-project {
  border-radius: 4px;
  box-shadow: 0 0 0 1px #eeeeee inset;
  transition: 100ms ease-in-out;
  transition-property: background, box-shadow;
  width: 100%;
  height: 155px;
  display: table;
  font-size: 14px;
  margin: 0 0 40px;
}

.projects-project:hover {
  background: #f5f5f5;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 1px #eeeeee inset, 0 -100px 0 -94px #5d60a5 inset;
}

.projects-project-inner {
  display: table-cell;
  padding: 35px;
  vertical-align: middle;
}

.projects-project-title {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 12px;
}

.projects-project-description {
  color: #717376;
}

.container {
  margin: auto;
  padding: 0 80px;
}

@media (max-width: 1238px) {
  .container {
    padding: 0 40px;
  }
}

@media (max-width: 568px) {
  .container {
    padding: 0 20px;
  }
}

@media (max-width: 320px) {
  .container {
    padding: 0 10px;
  }
}

.container-med {
  max-width: 772px;
  margin: auto;
}

.row {
  margin-bottom: 80px;
}

.columns-tight {
  margin-right: 0;
  margin-left: 0;
}

.columns-tight > [class*="column-"] {
  padding: 0 0;
}

.column-1-3:nth-child(3n + 1) {
  clear: left;
}

@media (max-width: 768px) {
  .column-1-3.column-tablet-1-2 {
    clear: none;
  }
  .column-1-3.column-tablet-1-2:nth-child(2n + 1) {
    clear: left;
  }
}

.content-header {
  color: #fff;
  padding: 165px 0 60px;
}

.section {
  padding: 80px 0;
}

.section-dark {
  background: #f5f5f5;
}

.section-divided {
  border-top: 1px #eeeeee solid;
}

.avatar {
  width: 60px;
  height: 60px;
  border: 3px #fff solid;
  border-radius: 50%;
}

.btn {
  transition: 100ms ease-in-out;
  transition-property: background;
  background: #4a89dc;
  border: 1px transparent solid;
  border-radius: 5px;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  padding: 22px 26px;
  text-align: center;
}

.btn, .btn:hover, .btn:active {
  color: #fff;
}

.btn:hover {
  background: #276ecc;
}

.btn:active {
  background: #1f57a1;
}

.btn-full {
  width: 100%;
}

.link {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: bold;
}

.jobs {
  color: #9e9fa5;
  font-size: 14px;
}

.footer-jobs {
  border-top: 1px #eeeeee solid;
  padding: 40px 0 0;
}

.home {
  text-align: center;
}

.home .content-header {
  min-height: 472px;
}

.home .content {
  padding: 1px 0 0;
}

.home .articles {
  margin: -80px 0 20px;
}

.home .jobs {
  margin: 30px 0 0;
}

.home-title {
  max-width: 830px;
  margin: 0 auto 70px;
}

.home-title > b {
  display: block;
  font-weight: 600;
  margin: 10px 0 0;
}

@media (max-width: 568px) {
  .home-title {
    font-size: 28px;
  }
}

@media (max-width: 480px) {
  .home-title {
    font-size: 24px;
  }
}

.articles-link {
  font-size: 14px;
}

.projects .columns {
  font-size: 0;
}

.projects [class*="column-"] {
  display: inline-block;
  float: none;
  font-size: 18px;
  vertical-align: top;
}

.quotes {
  margin: 0 0 -80px;
}

.quotes-quote {
  max-width: 315px;
  margin: 0 auto 80px;
}

@media (max-width: 980px) {
  .quotes-quote {
    max-width: 500px;
  }
}

.quotes-quote-title {
  color: #5d60a5;
  font-weight: bold;
  letter-spacing: 0.05em;
  line-height: 1.225;
  margin: 0 0 26px;
  text-transform: uppercase;
}

.quotes-quote-copy {
  min-height: 115px;
  font-size: 17px;
  line-height: 1.375;
  margin: 0 0 20px;
}

@media (max-width: 1318px) {
  .quotes-quote-copy {
    min-height: 0;
  }
}

.quotes-quote-author {
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
}

.quotes-quote-author-position {
  color: rgba(113, 115, 118, 0.7);
}

.team .columns {
  font-size: 0;
}

.team [class*="column-"] {
  display: inline-block;
  float: none;
  font-size: 18px;
  vertical-align: top;
}

.team-member {
  border-radius: 4px;
  box-shadow: 0 0 0 1px #eeeeee inset;
  transition: 100ms ease-in-out;
  transition-property: background, box-shadow;
  box-shadow: none;
  padding: 15px 15px 25px;
}

.team-member:hover {
  background: #f5f5f5;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 1px #eeeeee inset, 0 -100px 0 -94px #5d60a5 inset;
}

.team-member:hover .team-member-socials {
  opacity: 1;
}

.team-member-avatar {
  width: 100px;
  height: 100px;
  border: 0;
  margin: 0 0 18px;
}

.team-member-position {
  color: #9e9fa5;
  font-size: 14px;
  margin: 5px 0 0;
}

.team-member-position-detail {
  white-space: nowrap;
}

.team-member-socials {
  height: 17px;
  margin: 10px 0 0;
  opacity: 0;
  transition: 100ms ease-in-out;
  transition-property: opacity;
}

.touch .team-member-socials {
  opacity: 1;
}

.team-member-socials-social {
  width: 17px;
  height: 17px;
  display: inline-block;
  margin: 0 5px;
  overflow: hidden;
  text-indent: 150%;
  white-space: nowrap;
}

.team-member-socials-social-github {
  background: url("/img/socials/github.svg") center no-repeat;
}

.team-member-socials-social-twitter {
  background: url("/img/socials/twitter.svg") center no-repeat;
}

.team-member-socials-social-linkedin {
  background: url("/img/socials/linkedin.svg") center no-repeat;
}

.team-member-socials-social-facebook {
  background: url("/img/socials/facebook.svg") center no-repeat;
}

.team-member-socials-social-stack-overflow {
  background: url("/img/socials/stack-overflow.svg") center no-repeat;
}

.team-member-socials-social-twitter {
  width: 20px;
}

.team-member-socials-social-facebook {
  width: 9px;
}

.article-detail .content-header {
  background-image: url("/img/article.jpg");
}

.article-date {
  color: rgba(255, 255, 255, 0.5);
  display: block;
  font-size: 14px;
  margin: 0 0 30px;
}

.article-title {
  max-width: 600px;
  font-size: 30px;
  margin: 0 auto 30px;
}

.article-author {
  font-size: 14px;
}

.article-author-position {
  color: rgba(255, 255, 255, 0.5);
}

.article-copy {
  padding: 0 0 54px;
  text-align: left;
}

.article-copy th,
.article-copy td {
  padding: 0.5em 1em;
}

.article-copy th:first-child,
.article-copy td:first-child {
  padding-left: 0;
}

.article-copy th:last-child,
.article-copy td:last-child {
  padding-right: 0;
}

.article-copy th {
  color: #282c31;
  font-weight: 600;
}

.article-copy p,
.article-copy ol,
.article-copy ul,
.article-copy blockquote,
.article-copy table {
  line-height: 1.5;
  margin: 0 0 26px;
}

.article-copy h2,
.article-copy h3,
.article-copy h4 {
  color: #464d51;
  font-weight: 600;
  margin: 0 0 24px;
}

.article-copy h2 {
  font-size: 26px;
}

.article-copy h2:not(:first-child) {
  margin-top: 38px;
}

.article-copy h3 {
  font-size: 22px;
}

.article-copy h3:not(:first-child) {
  margin-top: 34px;
}

.article-copy h4:not(:first-child) {
  margin-top: 30px;
}

.article-copy ol > li > ul,
.article-copy ol > li > ol,
.article-copy ul > li > ul,
.article-copy ul > li > ol {
  margin: 0;
}

.article-copy ol > li + li,
.article-copy ul > li + li {
  margin: 3px 0 0;
}

.article-copy ol {
  list-style-type: decimal;
  padding: 0 0 0 1.5em;
}

.article-copy ul {
  list-style-type: disc;
  padding: 0 0 0 1.35em;
}

.article-copy blockquote {
  border-left: 5px #5d60a5 solid;
  color: #454d50;
  padding: 15px 0 15px 42px;
}

.article-copy blockquote p {
  font-size: 22px;
  line-height: 1.35;
  margin: 0;
  text-indent: -0.4em;
}

.article-copy blockquote p:before {
  content: '“';
}

.article-copy blockquote p:after {
  content: '”';
}

.article-copy p + ol,
.article-copy p + ul {
  margin-top: -14px;
}

.article-copy hr {
  margin: 26px 0;
}

.article-copy img {
  border-radius: 4px;
}

@media (max-width: 568px) {
  .article-copy {
    font-size: 15px;
  }
}

[class*="highlighter"] {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background: #f3f2f1;
  margin: -14px 0 26px;
  padding: 2px;
}

[class*="highlighter"]::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

[class*="highlighter"]::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 2px;
}

[class*="highlighter"]::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.1);
}

div[class*="highlighter"] {
  padding: 10px;
}

.archive .articles {
  margin: 0 0 40px;
}

.docs-sidebar {
  float: left;
  margin: 0 64px 0 0;
  text-align: left;
}

.docs-sidebar-item {
  font-weight: 600;
}

.docs-sidebar-item:not(:last-child) {
  margin-bottom: 16px;
}

@media (max-width: 768px) {
  .docs-sidebar {
    border-bottom: 1px #eeeeee solid;
    float: none;
    margin: 0 0 48px;
    padding: 0 0 48px;
  }
}

.docs-content {
  overflow: hidden;
}
